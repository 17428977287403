import { IDropDown } from "../../../components/DropdownGlobal";

export const listTranscriptionProviderAux: Array<IDropDown> = [
  { name: "MConf", id: 0 },
  { name: "Digitro", id: 1 },
];

export const listTimesAux: Array<IDropDown> = [
  { name: "30 minutos", id: 30 },
  { name: "60 minutos", id: 60 },
];

export const listDaysAux: Array<IDropDown> = [
  { name: "30 dias", id: 30 },
  { name: "60 dias", id: 60 },
];

export const listProviderAux: Array<IDropDown> = [
  { name: "Azure", id: 0 },
  { name: "Sistema de arquivos", id: 1 },
];

export const listFirstShortcut: Array<IDropDown> = [
  { name: "Nenhum", id: 0 },
  { name: "Alt", id: 1 },
  { name: "Shift", id: 2 },
  { name: "Ctrl", id: 3 },
];

export const resetPasswordDays: Array<IDropDown> = [
  { name: "Indefinido", id: 0 },
  { name: "10", id: 10 },
  { name: "20", id: 20 },
  { name: "30", id: 30 },
  { name: "40", id: 40 },
  { name: "50", id: 50 },
]

export const listPagination: Array<IDropDown> = [
  { name: "5", id: 5 },
  { name: "10", id: 10 },
  { name: "20", id: 20 },
  { name: "50", id: 50 },
  { name: "100", id: 100 },
];

export const listYesOrNoAux: Array<IDropDown> = [
  { name: "Não", id: 0 },
  { name: "Sim", id: 1 },
];

export const loginConfigAttemptAux: Array<IDropDown> = [
  { name: "3", id: 3 },
  { name: "5", id: 5 },
  { name: "7", id: 7 },
]

export const loginConfigPeriodAttemptsAux: Array<IDropDown> = [
  { name: "10", id: 10 },
  { name: "20", id: 20 },
  { name: "30", id: 30 },
]

export const listSecondShortcut: Array<IDropDown> = [
  { name: "Espaço", id: 0 },
  { name: "F1", id: 1 },
  { name: "F2", id: 2 },
  { name: "F3", id: 3 },
  { name: "F4", id: 4 },
  { name: "F5", id: 5 },
  { name: "F6", id: 6 },
  { name: "F7", id: 7 },
  { name: "F8", id: 8 },
  { name: "F9", id: 9 },
  { name: "F10", id: 10 },
  { name: "F11", id: 11 },
  { name: "F12", id: 12 },
  { name: "Seta esquerda", id: 13 },
  { name: "Seta cima", id: 14 },
  { name: "Seta direita", id: 15 },
  { name: "Seta baixo", id: 16 },
];

export const listSecondsToNextBack: Array<IDropDown> = [
  { name: "5 segundos", id: 5 },
  { name: "10 segundos", id: 10 },
  { name: "20 segundos", id: 20 },
  { name: "30 segundos", id: 30 },
  { name: "60 segundos", id: 60 },
];

//Digitro = Provedor 1, Kenta = Provedor 2 
export const listWaterMarkProvider: Array<IDropDown> = [
  { name: "Provedor 1", id: 0 },
  { name: "Provedor 2", id: 1 },
];

//Digitro Provedor 1, Google Provedor 2, OpenAI Provedor 3
export const editConfig: Array<IDropDown> = [
  { name: "Provedor 1", id: 1 },
  { name: "Provedor 2", id: 2 },
  { name: "Provedor 3", id: 3 },
]

//1 - Digitro - Provedor 1 | 2 - Teams - Provedor 2
export const listRecordProvider: Array<IDropDown> = [
  { name: "Provedor 1", id: 1 },
  { name: "Provedor 2", id: 2 }
]