import styled from "styled-components";

export const Container = styled.div`
  width: 460px;
  height: calc(100vh - 144px);
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  padding: 20px 40px;
`;

export const StatusText = styled.div`
  width: 100%;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  color: rgb(120, 135, 169);
  font-family: "Inter Tight", Arial, Helvetica, sans-serif;
`;

export const StatusItem = styled.div`
  font-size: 18px;
  color: #7887a9;
  margin-top: 24px;
  font-family: "Inter Tight", Arial, Helvetica, sans-serif;
`;

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

